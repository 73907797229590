import { tns } from 'tiny-slider';

[...document.querySelectorAll('.slider')].forEach((el) => {
  const slides = el.querySelector('.slider__slides');
  const progressBar = el.querySelector('.slider__progress');
  let timeoutId = null;

  if (!slides || !progressBar) {
    return;
  }

  const slider = tns({
    container: slides,
  });

  slider.events.on('indexChanged', () => {
    stopAutoplay();
    window.setTimeout(startAutoplay, 0);
  });

  function startAutoplay() {
    progressBar.classList.add('slider__progress--running');
    timeoutId = window.setTimeout(function () {
      slider.goTo('next');
    }, 8000);
  }

  function stopAutoplay() {
    progressBar.classList.remove('slider__progress--running');
    if (timeoutId) {
      window.clearTimeout(timeoutId);
      timeoutId = null;
    }
  }

  startAutoplay();
});
