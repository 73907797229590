[...document.querySelectorAll('[data-widget="product-finder"]')].forEach((container) => {
  const mapping = JSON.parse(container.dataset.productFinderMapping);

  const resultsPlaceholderElement = container.querySelector('[data-product-finder-target="results-placeholder"]');
  const productElements = [...container.querySelectorAll('[data-product-finder-target="product"]')];
  const filterFormElement = container.querySelector('[data-product-finder-target="filter-form"]');
  const filterElements = [...container.querySelectorAll('[data-product-finder-target="filter"]')].filter((select) => !!select.dataset.productFinderField);

  function getCurrentFilter() {
    let filter = {};

    filterElements.forEach((select) => {
      if (!select.value) {
        return;
      }

      const field = select.dataset.productFinderField;

      filter[field] = select.value;
    });

    return filter;
  }

  function updateResults() {
    if (!filterFormElement.checkValidity()) {
      clearResults();
    } else {
      const products = getProducts(getCurrentFilter());

      resultsPlaceholderElement.hidden = true;
      productElements.forEach((element) => {
        element.hidden = -1 === products.indexOf(element.dataset.productFinderProduct);
      });
    }
  }

  function clearResults() {
    resultsPlaceholderElement.hidden = false;
    productElements.forEach((element) => {
      element.hidden = true;
    });
  }

  function updateFilters() {
    const currentFilter = getCurrentFilter();

    filterElements.forEach((select) => {
      const field = select.dataset.productFinderField;

      [...select.children].forEach((option) => {
        if (!option.value) {
          return;
        }

        let modifiedFilter = Object.assign({}, currentFilter);
        modifiedFilter[field] = option.value;

        option.disabled = !hasProducts(modifiedFilter);
      });
    });
  }

  function getProducts(filter) {
    return mapping
      .filter((row) => Object.entries(filter).every(([field, value]) => row[field] === value))
      .map((row) => row.product)
      .filter((value, index, self) => index === self.indexOf(value));
  }

  function hasProducts(filter) {
    return mapping.some((row) => Object.entries(filter).every(([field, value]) => row[field] === value));
  }

  filterElements.forEach((select) => {
    const field = select.dataset.productFinderField;

    mapping
      .filter((row) => 'undefined' !== typeof row[field] && '' !== row[field])
      .map((row) => row[field])
      .filter((value, index, self) => index === self.indexOf(value))
      .sort()
      .forEach((value) => {
        const option = document.createElement('option');
        option.value = value;
        option.text = value;

        select.appendChild(option);
      });

    select.addEventListener('input', () => {
      window.setTimeout(updateFilters, 10);
    }, false);
  });

  filterFormElement.addEventListener('submit', (event) => {
    event.preventDefault();
    updateResults();
  });

  filterFormElement.addEventListener('reset', () => {
    clearResults();
    window.setTimeout(updateFilters, 10);
  });
});
