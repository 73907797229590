import fontsLoaded from '../../utils/fontsLoaded';

class LongLinkWrapper {
  constructor() {
    this.links = [...document.querySelectorAll('.richtext a')].map((el) => {
      return {
        el: el,
        wrapped: false,
      }
    })
    this.init();
  }

  init() {
    this.update = this.update.bind(this);
    this.requestUpdate = () => {
      if (!this.updateRequested) {
        this.updateRequested = true;
        requestAnimationFrame(this.update);
      }
    }
    window.addEventListener('resize', this.requestUpdate);
    fontsLoaded.then(this.requestUpdate);
    this.update();
  }

  update() {
    this.updateRequested = false;

    const widths = this.links.map((link) => link.el.getBoundingClientRect().width);
    const parentWidths = this.links.map((link) => link.el.parentNode.clientWidth);

    this.links.forEach((link,idx) => {
      if (widths[idx] > parentWidths[idx]) {
        link.el.classList.add('js-long-link-wrap');
        link.wrapped = true;
      }
    })

    this.links = this.links.filter((link) => !link.wrapped);
  }
}

new LongLinkWrapper();
