const scrollFadeIn = (elements) => {
  if ('IntersectionObserver' in window) {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        const el = entry.target;
        const bbox = entry.boundingClientRect;

        if (entry.isIntersecting) {
          el.classList.add('js-visible');
        }
      });
    }, {
      root: null,
      rootMargin: "0px",
      threshold: 0.5
    });

    elements.forEach((el) => {
      observer.observe(el);
    });
  } else {
    // IE 11 Fallback
    elements.forEach((el) => {
      el.classList.add('js-visible');
    });
  }
}

export default scrollFadeIn;
