import FontFaceObserver from 'fontfaceobserver';

var Helvetica300 = new FontFaceObserver('Helvetica', {
  weight: 300
});
var Helvetica400 = new FontFaceObserver('Helvetica', {
  weight: 400
});
var Helvetica700 = new FontFaceObserver('Helvetica', {
  weight: 700
});

const fontsLoaded = Promise.all([
  Helvetica300.load(),
  Helvetica400.load(),
  Helvetica700.load(),
])

export default fontsLoaded;
