import BigPicture from 'bigpicture'

class VideoStage {

  constructor(el) {
    const youtubeId = el.dataset.youtubeId;
    const videoSrc = el.dataset.videoSrc;

    if (!youtubeId && !videoSrc) return;

    this.el = el;
    this.youtubeId = youtubeId;
    this.videoSrc = videoSrc;
    this.init();
  }

  init() {
    this.onClick = this.onClick.bind(this);
    this.button = this.el.querySelector('button');
    if (!this.button) return;

    this.button.addEventListener('click', this.onClick);
  }

  onClick() {
    const bpOptions = {
      // element from which animation starts (required)
      el: this.el,
      // show or hide default loading indicator
      noLoader: false,
    }

    if (this.youtubeId) {
      bpOptions.ytNoCookie = true;
      bpOptions.ytSrc = this.youtubeId;
    } else if (this.videoSrc) {
      bpOptions.vidSrc = this.videoSrc;
    }

    this.bp = BigPicture(bpOptions);
  }
}

[...document.querySelectorAll('.video-stage')].forEach((el) => new VideoStage(el));
