let links = [
  {
    selector: '.richtext .external-link',
    icon: 'diagonal-arrow-right-up',
  },
  {
    selector: '.richtext .download-link',
    icon: 'download',
  }
];

links.forEach((link) => {
  [...document.querySelectorAll(link.selector)].forEach((el) => {
    let svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttributeNS(null, 'class', 'icon');
    svg.innerHTML = `<use href="${window.ahData.iconSpritemap}#${link.icon}"/>`;
    let span = document.createElement('span');
    span.textContent = el.textContent;
    el.innerHTML = '';
    el.appendChild(svg);
    el.appendChild(span);
  });
})
