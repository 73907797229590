import scrollFadeIn from '../utils/scrollFadeIn';

[...document.querySelectorAll('.claim')].forEach((el) => {
  const keywords = el.dataset.keywords ? JSON.parse(el.dataset.keywords) : [];
  const keywordEl = el.querySelector('.claim__keyword');

  scrollFadeIn(el.querySelectorAll('.image'));
  scrollFadeIn(el.querySelectorAll('.claim__line'));

  if (!Array.isArray(keywords) || 2 > keywords.length || !keywordEl) {
    return;
  }

  function switchKeyword() {
    const nextKeyword = keywords.shift();
    keywords.push(nextKeyword);

    keywordEl.textContent = nextKeyword;

    keywordEl.addEventListener('animationend', () => {
      keywordEl.classList.remove('claim__keyword--fade-in');

      window.setTimeout(() => {
        keywordEl.addEventListener('animationend', () => {
          keywordEl.classList.remove('claim__keyword--fade-out');

          switchKeyword();
        }, {
          once: true,
        });

        keywordEl.classList.add('claim__keyword--fade-out');
      }, 2500);
    }, {
      once: true,
    });

    keywordEl.classList.add('claim__keyword--fade-in');
  }

  switchKeyword();
});
