/*
* Select placeholder styling
*/
const checkHasValue = function(group, checkbox) {
  if (checkbox.checked) group.classList.add('toggled');
  else group.classList.remove('toggled');
};

[...document.querySelectorAll('.toggle-group')].forEach((group) => {
  const checkbox = group.querySelector('.checkbox input[type="checkbox"]');
  if (!checkbox) return;

  const boundFunction = () => checkHasValue(group, checkbox);
  checkbox.addEventListener('change', boundFunction);
  boundFunction()
})
