/* https://stackoverflow.com/a/30303898 */

function hoverDetection() {
  var hasHoverClass = false;
  var htmlEl = document.documentElement;
  var lastTouchTime = 0;

  function enableHover() {
      // filter emulated events coming from touch events
      if (new Date() - lastTouchTime < 500) return;
      if (hasHoverClass) return;

      htmlEl.classList.add('has-hover')
      hasHoverClass = true;
  }

  function disableHover() {
      if (!hasHoverClass) return;

      htmlEl.classList.remove('has-hover');
      hasHoverClass = false;
  }

  function updateLastTouchTime() {
      lastTouchTime = new Date();
  }

  document.addEventListener('touchstart', updateLastTouchTime, true);
  document.addEventListener('touchstart', disableHover, true);
  document.addEventListener('mousemove', enableHover, true);

  enableHover();
}

hoverDetection();