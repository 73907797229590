import '@babel/polyfill';
import svg4everybody from 'svg4everybody'
import './utils/objectFitPolyfill'
import 'lazysizes'  // lazyloading
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'focus-visible'
import 'unfetch/polyfill'
import 'element-closest-polyfill'

import './utils/hoverDetection'
import './utils/autosuggest'
import './utils/smoothScroll'
import './utils/typo3'
import './utils/hyphenator'
import './utils/height'
import './utils/klaro'

import './components/formfields/floating-label'
import './components/formfields/toggle-group'
import './components/accordion'
import './components/claim'
import './components/eppgLines'
import './components/lightbox'
import './components/lineTeaser'
import './components/links'
import './components/navigation'
import './components/productFinder'
import './components/richtext/link-icons'
import './components/richtext/long-link-wrapper'
import './components/formValidation'
import './components/quotes'
import './components/slider'
import './components/videoStage'

svg4everybody();
