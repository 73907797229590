/*
* Select placeholder styling
*/
const checkHasValue = function () {
  if (this.value) {
    this.classList.add('has-value');
  } else {
    this.classList.remove('has-value');
  }
};

[...document.querySelectorAll('.text-field input, .text-area textarea, .text-field textarea, .select-field select')].forEach((selectEl) => {
  selectEl.addEventListener('change', checkHasValue);

  checkHasValue.call(selectEl);
});
