import gsap from 'gsap';

class Animation {
  cols = 0;
  rows = 0;

  lineWidth = 14;
  spacingHorizontal = 10;
  spacingVertical = 6;
  strokeColor = 'currentColor';
  strokeWidth = 1;

  svgMarginX = 0;
  svgMarginY = 0;
  svgHeight = 0;
  svgWidth = 0;

  lines = [];
  screen = {
    width: window.innerWidth,
    height: window.innerHeight,
  };
  mouse = {
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  };
  mouseStored = Object.assign({}, this.mouse);

  constructor(svg) {
    this.svg = svg;
    this.container = svg.parentElement;
    this.lines = [];

    this.setDimensions();
    this.addEventListeners();

    // Draw all the lines
    this.draw();
    // And animate them if the user is fine with it
    window.matchMedia('(prefers-reduced-motion: no-preference)').matches ? this.animate() : null;

    const self = this;

    const resizeObserver = new ResizeObserver(() => {
      self.lines.forEach(line => {
        line.remove();
      });
      self.lines.splice(0, self.lines.length);
      self.setDimensions();
      self.draw();
    });

    resizeObserver.observe(this.container);
  }

  setDimensions() {
    this.magnetic = `${this.svg.dataset?.magnetic}` === 'true';

    const clientWidth = Math.floor(this.container.clientWidth);
    const clientHeight = Math.floor(this.container.clientHeight);

    this.cols = Math.floor((clientWidth + this.spacingHorizontal) / (this.lineWidth + this.spacingHorizontal));
    this.svgMarginX = (clientWidth - (this.lineWidth * this.cols + this.spacingHorizontal * (this.cols - 1))) / 2;
    this.svgWidth = clientWidth;

    this.rows = Math.floor((clientHeight + this.spacingVertical) / (this.lineWidth + this.spacingVertical));
    this.svgMarginY = (clientHeight - (this.lineWidth * this.rows + this.spacingVertical * (this.rows - 1))) / 2;
    this.svgHeight = clientHeight;

    this.svg.setAttribute('width', clientWidth);
    this.svg.setAttribute('height', clientHeight);
    this.svg.setAttribute('viewBox', `0 0 ${clientWidth} ${clientHeight}`);
    this.svg.setAttribute('stroke-linecap', 'square');
  }

  addEventListeners() {
    let self = this;
    // Don't redraw everything, only recalculate the centers of all arrows
    window.addEventListener('resize', function () {
      self.screen.width = window.innerWidth;
      self.screen.height = window.innerHeight;
      self.setLineCenters();
    });
  }

  getPercentage(partial, total) {
    return (partial * 100) / total;
  }

  draw() {
    for (var i = 0; i < this.rows; i++) {
      for (var j = 0; j < this.cols; j++) {
        // We're drawing the initial lines horizontally
        let target = {
          x1: this.svgMarginX + j * this.lineWidth + j * this.spacingHorizontal,
          x2: this.svgMarginX + (j + 1) * this.lineWidth + j * this.spacingHorizontal,
          y1: this.svgMarginY + (i + 1.5) * this.lineWidth + (i - 1) * this.spacingVertical,
          y2: this.svgMarginY + (i + 0.5) * this.lineWidth + (i - 1) * this.spacingVertical,
        };

        let c = new Line(
          this.svgMarginX + (j + 0.5) * this.lineWidth + j * this.spacingHorizontal,
          this.svgMarginX + (j + 0.5) * this.lineWidth + j * this.spacingHorizontal,
          this.svgMarginY + (i + 1) * this.lineWidth + (i - 1) * this.spacingVertical,
          this.svgMarginY + (i + 1) * this.lineWidth + (i - 1) * this.spacingVertical,
          this.strokeColor,
          this.strokeWidth
        );

        // Set a transform origin and add the HTML element to the SVG
        const cElement = c.getElement();
        gsap.set(cElement, { transformOrigin: '50% 50%' });
        this.svg.appendChild(cElement);

        this.lines.push(cElement);
        gsap
        .to(cElement, {
          attr: {
            x1: target.x1,
            x2: target.x2,
            y1: target.y1,
            y2: target.y2,
          },
        })
        .delay(1 + (this.svgHeight - target.y1 + target.x1) / 1000);
      }
    }

    this.setLineCenters();
  }

  setMouseCoords(event) {
    this.mouse.x = event.clientX;
    this.mouse.y = event.clientY;
  }

  setLineCenters() {
    this.lines.forEach(line => {
      // Get the center of the line
      // Instead of mapping svg coords to the screen position, get the position on the actual screen using boundingRect
      const boundingRect = line.getBoundingClientRect();
      line.center = {
        x: boundingRect.x + boundingRect.width / 2,
        y: boundingRect.y + boundingRect.height / 2,
      };
    });
  }

  animate() {
    const r = this.lineWidth / 2;
    // Listen for the mouse movements
    this.svg.addEventListener('mousemove', this.setMouseCoords.bind(this));
    this.svg.addEventListener('mouseleave', event => {
      this.lines.forEach(line => {
        gsap.to(line, 1, {
          rotation: '0_short',
          strokeWidth: 1,
          scaleX: 1,
          scaleY: 1,
        });
      });
    });

    // And use the ticker to update the rotation accordingly
    gsap.ticker.add(this.setLineRotation.bind(this));
  }

  setLineRotation() {
    // Don't do anything if the cursor's position is the same as in the previous tick
    if ((this.mouseStored.x === this.mouse.x && this.mouseStored.y === this.mouse.y) || !this.magnetic) {
      return;
    }

    this.lines.forEach(line => {
      // Calculate the rotation, convert it to deg, and offset it to account for the lines' initial direction
      const angle = Math.atan2(this.mouse.x - line.center.x, -(this.mouse.y - line.center.y)) * (180 / Math.PI) - 90;
      const distance = Math.sqrt(Math.pow((line.center.x - this.mouse.x) / this.svgWidth, 2) + Math.pow((line.center.y - this.mouse.y) / this.svgWidth, 2));
      const scale = Math.min(2 / Math.pow(1.5 - distance, 4), 1);
      const radius = 0.13;

      gsap.to(line, {
        // Use the shortest way to get to the destination angle
        rotation: distance < radius ? `${angle + 45}_short` : '0_short',
        strokeWidth: distance < radius ? 1 / scale : 1,
        scaleX: distance < radius ? scale : 1,
        scaleY: distance < radius ? scale : 1,
      });
    });

    // Store the mouse position for the next tick
    this.mouseStored.x = this.mouse.x;
    this.mouseStored.y = this.mouse.y;
  }
}

class Line {
  x1;
  x2;
  y1;
  y2;
  strokeColor;
  strokeWidth;
  element;

  constructor(x1, x2, y1, y2, strokeColor, strokeWidth) {
    this.x1 = x1;
    this.x2 = x2;
    this.y1 = y1;
    this.y2 = y2;
    this.strokeColor = strokeColor;
    this.strokeWidth = strokeWidth;
    this.element = document.createElementNS('http://www.w3.org/2000/svg', 'line');

    this.setElement();
  }

  getElement() {
    return this.element;
  }

  setElement(x1, x2, y1, y2, strokeColor, strokeWidth) {
    this.element.setAttribute('x1', x1 ? x1 : this.x1);
    this.element.setAttribute('x2', x2 ? x2 : this.x2);
    this.element.setAttribute('y1', y1 ? y1 : this.y1);
    this.element.setAttribute('y2', y2 ? y2 : this.y2);
    this.element.style.stroke = strokeColor ? strokeColor : this.strokeColor;
    this.element.style.strokeWidth = strokeWidth ? strokeWidth : this.strokeWidth;
  }
}

const observer = new IntersectionObserver((entries) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      observer.unobserve(entry.target);
      new Animation(entry.target);
    }
  });
}, {
  threshold: 0.5,
});

const selectors = [
  '.eppg-stage__bg-lines svg',
  '.quotes__eppg-lines svg',
  '.line-teaser__eppg-lines svg',
  '.text-image__eppg-lines svg',
];

[...document.querySelectorAll(selectors.join(','))].forEach((element) => {
  observer.observe(element);
});
