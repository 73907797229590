import fontsLoaded from '../../utils/fontsLoaded';

class Collapsible {

  constructor(el, options = {}) {
    this.el = el;
    if (options.onToggleClick) this.onToggleClick = options.onToggleClick;

    this.init();
  }

  init() {
    this.toggleBtn = this.el.querySelector('.js-toggle');
    this.collapseWrapper = this.el.querySelector('.js-collapse-wrapper');
    this.content = this.collapseWrapper.querySelector('.js-content');

    this.collapsed = !this.toggleBtn.classList.contains('active');
    this.update = this.update.bind(this);
    this.update();

    this.requestUpdate = this.requestUpdate.bind(this);
    window.addEventListener('resize', this.requestUpdate);
    fontsLoaded.then(this.requestUpdate);

    this.toggleBtn.addEventListener('click', () => this.onToggleClick(this));
  }

  requestUpdate() {
    if (!this.raf) requestAnimationFrame(this.update);
  }

  onToggleClick() {
    this.toggleCollapsed();
  }

  toggleCollapsed(collapsed) {
    if (typeof collapsed !== 'undefined') {
      this.collapsed = collapsed;
    } else {
      this.collapsed = !this.collapsed;
    }
    this.update();
  }

  update() {
    if (!this.collapsed) {
      this.collapseWrapper.style.maxHeight = this.content.clientHeight + 'px';
      this.toggleBtn.classList.add('active');
      this.toggleBtn.setAttribute('aria-expanded', true);
    } else {
      this.collapseWrapper.style.maxHeight = '';
      this.toggleBtn.classList.remove('active');
      this.toggleBtn.setAttribute('aria-expanded', false);
    }

    this.raf = false;
  }
}

export default Collapsible;
