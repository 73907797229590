import closest from '../utils/closest';

class AutoSuggest {
  constructor(el) {
    this.el = el;

    this.init();
    this.initKeyboard();
    this.initClickOutside();
    this.initFocusOutside();
  }

  init() {
    this.input = this.el.querySelector('input');
    this.endpoint = this.el.dataset.suggest;
    this.paramName = this.el.dataset.suggestParamName || 'q';
    this.suggestionsContainer = this.el.querySelector('.js-autosuggest__suggestions');

    if (!this.input || !this.endpoint) return;
    this.endpointLink = document.createElement('a');
    this.endpointLink.href = this.endpoint;

    this.form = closest(this.input, (el) => el.nodeName === 'FORM');

    this.onChange = this.onChange.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.input.addEventListener('change', this.onChange);
    this.input.addEventListener('input', this.onChange);
    this.input.addEventListener('keyup', this.onKeyUp);

    this.onOptionClick = this.onOptionClick.bind(this);
  }

  onKeyUp(evt) {
    // filter out control keys and don't update the suggestion box
    switch (evt.key) {
      case "Down":
      case "ArrowDown":
      case "Up":
      case "ArrowUp":
      case "Esc": // IE/Edge specific value
      case "Escape":
        return;
      default:
    }
    this.onChange(evt);
  }

  onChange() {
    if (this.input.value.length > 2) {
      this.updateAutoSuggestions();
    } else {
      this.hideAutoSuggestions();
    }
  }

  updateAutoSuggestions() {
    this.autoSuggestionsVisible = true;
    // throttled and with timestamp in case another request comes in late
    if (this.updateRequested) clearTimeout(this.updateRequested);

    this.updateRequested = setTimeout(() => {
      const currentTime = +(Date.now());

      (() => {
        const fetchRequestTime = currentTime;
        const querySeparator = -1 === this.endpoint.indexOf('?') ? '?' : '&';
        const value = encodeURIComponent(this.input.value);
        fetch(`${this.endpoint}${querySeparator}${this.paramName}=${value}`)
          .then((res) => res.json())
          .then((res) => {
            if (fetchRequestTime === this.lastFetchRequestTime && this.autoSuggestionsVisible) {
              this.showAutoSuggestions(res.suggestions);
            }
          })
          .catch(error => console.error(error));
      })();

      this.lastFetchRequestTime = currentTime;
    }, 100);
  }

  showAutoSuggestions(results) {
    const fragment = document.createDocumentFragment();

    let keys = Object.keys(results);
    keys.sort((a, b) => results[b] - results[a]);

    keys.forEach((key) => {
      const entry = document.createElement('div');
      entry.classList.add('js-autosuggest__entry');
      // var svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      // svg.setAttributeNS(null, 'class', 'icon');
      // svg.innerHTML = `<use href="${window.ahData.iconSpritemap}#search"/>`;
      var span = document.createElement('span');
      span.textContent = key;
      span.classList.add('text-m');
      // entry.appendChild(svg);
      entry.appendChild(span);

      entry.dataset.value = key;

      entry.addEventListener('click', () => {
        this.input.value = key;
        this.form.submit();
      });

      fragment.appendChild(entry);
    });

    this.suggestionsContainer.innerHTML = '';
    this.suggestionsContainer.appendChild(fragment);
    this.currentEntries = this.suggestionsContainer.childNodes;
    this.currentEntryIndex = -1;
  }

  hideAutoSuggestions() {
    this.autoSuggestionsVisible = false;
    this.suggestionsContainer.innerHTML = '';
  }

  onOptionClick() {
    this.hideAutoSuggestions();
  }

  initKeyboard() {
    this.onKeyDown = this.onKeyDown.bind(this);
    this.el.addEventListener('keydown', this.onKeyDown);
  }

  onKeyDown(evt) {
    if (evt.defaultPrevented) {
      return; // Do nothing if the event was already processed
    }

    if (document.activeElement !== this.input) {
      return;
    }

    switch (evt.key) {
      case "Down": // IE/Edge specific value
      case "ArrowDown":
        this.nextEntry();
        evt.preventDefault();
        break;
      case "Up": // IE/Edge specific value
      case "ArrowUp":
        this.prevEntry();
        evt.preventDefault();
        break;
      case "Esc": // IE/Edge specific value
      case "Escape":
        this.hideAutoSuggestions()
        break;
      default:
        return; // Quit when this doesn't handle the key event.
    }
  }

  nextEntry() {
    if (!this.autoSuggestionsVisible) return;
    this.currentEntryIndex = Math.min(this.currentEntries.length - 1, this.currentEntryIndex + 1);
    this.updateEntriesActiveState();
  }

  prevEntry() {
    if (!this.autoSuggestionsVisible) return;
    this.currentEntryIndex = Math.max(-1, this.currentEntryIndex - 1);
    this.updateEntriesActiveState();
  }

  updateEntriesActiveState() {
    if (this.currentEntryIndex === -1) return this.hideAutoSuggestions();

    this.currentEntries.forEach((entry) => entry.classList.remove('active'));
    this.currentEntries[this.currentEntryIndex].classList.add('active');
    this.input.value = this.currentEntries[this.currentEntryIndex].dataset.value;
  }

  initClickOutside() {
    window.addEventListener('click', () => {
      if (this.autoSuggestionsVisible) {
        this.hideAutoSuggestions();
      }
    });

    this.el.querySelector('.js-autosuggest__suggestions').addEventListener('click', (evt) =>  evt.stopPropagation());
  }

  initFocusOutside() {
    document.addEventListener('focusin', (evt) => {
      if (this.autoSuggestionsVisible && !this.el.contains(evt.target)) {
        this.hideAutoSuggestions();
      }
    });
  }
}

[...document.querySelectorAll('.js-autosuggest')].forEach((el) => new AutoSuggest(el));
