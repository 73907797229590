import fontsLoaded from './fontsLoaded';

class Hyphenator {
  constructor() {
    this.targets = [...document.querySelectorAll('h1, h2, h3, p, li, blockquote, figcaption, span, label')];

    this.update = this.update.bind(this);
    this.requestUpdate = this.requestUpdate.bind(this);
    window.addEventListener('resize', this.requestUpdate);
    fontsLoaded.then(this.requestUpdate);
    this.requestUpdate();
  }

  requestUpdate() {
    if (!this.updateRequested) {
      this.updateRequested = true;
      requestAnimationFrame(this.update);
    }
  }

  update() {
    if (this.targets.length === 0) return;

    const tooLarge = this.targets.filter((el) => el.scrollWidth > el.clientWidth);
    tooLarge.forEach((el) => el.classList.add('hyphenate'));

    // remove elements from list of targets
    tooLarge.forEach((el) => this.targets.splice(this.targets.indexOf(el), 1));

    this.updateRequested = false;
  }
}

new Hyphenator();
