import * as Klaro from 'klaro/dist/klaro-no-css';

window.klaro = Klaro;

window.addEventListener('DOMContentLoaded', () => {
  [...document.querySelectorAll('.cookie-settings')].forEach((link) => {
    link.addEventListener('click', (event) => {
      event.preventDefault();

      Klaro.show();
    }, true);
  });
});
