import { tns } from 'tiny-slider';
import scrollFadeIn from '../utils/scrollFadeIn';

[...document.querySelectorAll('.quotes')].forEach((el) => {
  const slides = el.querySelector('.quotes__slides');

  if (!slides) {
    return;
  }

  const slider = tns({
    container: slides,
    controls: false,
    mode: 'gallery',
  });

  slider.events.on('indexChanged', function (event) {
    [...event.slideItems].forEach((slide) => {
      if (!slide.classList.contains('tns-slide-active')) {
        [...slide.querySelectorAll('.js-visible')].forEach((animatedElement) => {
          animatedElement.classList.remove('js-visible');
        });
      }
    });
  });

  scrollFadeIn(el.querySelectorAll('.quotes__eppm-line'));
});
