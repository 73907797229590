import SmoothScroll from 'smooth-scroll';
import fontsLoaded from './fontsLoaded';

const scroll = new SmoothScroll('a[href*="#"]:not([href="#"])', {
  speed: 500,
  speedAsDuration: true,
  header: '.navigation .navigation__content',
  ignore: '[data-scroll-ignore]',

  // History
  updateURL: true, // Update the URL on scroll
  popstate: true, // Animate scrolling with the forward/backward browser buttons (requires updateURL to be true)
  emitEvents: true, // Emit custom events
});

// initial scroll to target
if (location.hash) {
  history.scrollRestoration = "manual";
  window.scrollTo(0, 0);

  fontsLoaded.then(() => requestAnimationFrame(() => {
    const target = document.querySelector(location.hash);
    if (target) scroll.animateScroll(target);
  }));
}
