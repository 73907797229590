const navEl = document.querySelector('.navigation');

class Navigation {
  constructor(el) {
    if (!el) return;

    this.el = el;
    // ordered calls
    this.initMobileMenu();
    this.initTouch();
    this.initClickOutside();

    this.html = document.querySelector('html');
  }

  initMobileMenu() {
    //
    //    Open / Close
    //
    this.htmlEl = document.documentElement;
    this.menuBtnEl = this.el.querySelector('.navigation__menu-btn button');
    this.mobileMenuEl = this.el.querySelector('.navigation__mobile-menu');
    this.mobileMenuFocusable = [...this.mobileMenuEl.querySelectorAll('button, a')];
    this.mobileMenuFocusable.forEach((el) => el.setAttribute('tabindex', '-1'));

    this.menuOpen = false;
    this.menuBtnEl.addEventListener('click', () => {
      this.menuOpen = !this.menuOpen;

      if (this.menuOpen) {
        this.openMobileMenu();
      } else {
        this.closeMobileMenu();
      }
    });
  }

  openMobileMenu() {
    this.mobileMenuEl.classList.add('open');
    this.menuBtnEl.classList.add('open');
    this.htmlEl.classList.add('locked');
    this.mobileMenuFocusable.forEach((el) => el.setAttribute('tabindex', ''));
  }

  closeMobileMenu() {
    this.mobileMenuEl.classList.remove('open');
    this.menuBtnEl.classList.remove('open');
    this.htmlEl.classList.remove('locked');
    this.mobileMenuFocusable.forEach((el) => el.setAttribute('tabindex', '-1'));
  }

  initTouch() {
    this.touchButtons = this.el.querySelectorAll('[data-tablet-touch]');
    this.onTouch = this.onTouch.bind(this)

    this.touchButtons.forEach((el) => {
      el.addEventListener('touchstart', this.onTouch)
    });
  }

  onTouch(evt) {
    evt.preventDefault();
    this.clearTouched();
    evt.currentTarget.classList.add('js-touched')
  }

  clearTouched() {
    this.touchButtons.forEach((el) => el.classList.remove('js-touched'));
  }

  initClickOutside() {
    window.addEventListener('click', (evt) => {
      if (window.innerWidth < 1200 && evt.target.closest('.navigation')) return;
      if (evt.target.closest('.navigation__main-nav')) return;

      if (this.menuOpen) {
        this.menuOpen = false;
        this.closeMobileMenu();
      }

      this.clearTouched();
    });
  }
}

const nav = new Navigation(navEl);
