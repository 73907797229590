import BigPicture from 'bigpicture'

[...document.querySelectorAll('.lightbox')].forEach((el) => {
  el.addEventListener('click', (event) => {
    event.preventDefault();

    BigPicture({
      el: el,
      imgSrc: el.href,
    });
  });
});
